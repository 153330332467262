.purple {
    background-color: #7030A0 !important;
    text-align: center !important;
    color: #ffffff !important;
}

.skyblue {
    background-color: #0C5393 !important;
    text-align: center !important;
    color: #ffffff !important;
}

.yellow {
    background-color: lightyellow !important;
    text-align: center !important;
    color: black !important;
}

.width {
    min-width: 30px !important;
}
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    width: 320px;
    top: 100%;
    left: -120px;
    margin: 3px;
    line-height: 1.6;
}