.styled_table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow: scroll;
}

.styled_table thead tr {
    background-color: #0C5393;
    color: #ffffff;
    text-align: left;
}

.styled_table th,
.styled_table td {
    padding: 12px 15px;
}

.styled_table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled_table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled_table tbody tr:last-of-type {
    border-bottom: 2px solid #0C5393;
}

.styled_table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    width: 320px;
    top: 100%;
    left: -120px;
    margin: 3px;
    line-height: 1.6;
}